@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import 'node_modules/bootstrap/scss/functions';

@import 'variables';

.btn-primary,
.btn-danger,
.btn-info,
.btn-success {
  color: #ffffff !important;
}

.form-control:disabled {
  opacity: 0.3 !important;
}

@import 'node_modules/bootstrap/scss/bootstrap';

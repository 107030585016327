@import 'themes';
@import 'misc';
@import '~bootstrap-icons/font/bootstrap-icons';

html,
body {
  height: 100vh;
  background: #1b1b38;
}

.firebase-emulator-warning {
  display: none;
}

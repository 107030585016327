.command {
  font-weight: 700;
  background: var(--bs-white);
  color: var(--bs-dark);
  border-radius: 3px;
  padding: 2px 4px 3px 4px;
  box-shadow: var(--shadow);
  border: 1px solid white;
  border-bottom: 2px solid #92929e;
}

h1.title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: 1rem;
}

fieldset {
  position: relative;
  padding: 20px 10px 10px 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);

  legend {
    position: absolute;
    top: -10px;
    width: auto;
    background: var(--bs-dark);
    padding: 0 10px;
    font-size: 90%;
    font-weight: bold;
  }
}
